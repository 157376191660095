<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Promozioni"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <ExportExcelTable
        :worksheetName="worksheetName"
        :fileName="fileName"
        :fieldsToExport="fieldsToExport"
        :fetch="fetchExcel"
      > </ExportExcelTable>
      <v-btn text link @click="goToNew" :disabled="isLicenseBlocked">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>
    </template>
    <template v-slot:content>
      <div class="d-flex justify-center align-center mb-3" style="width: 100%; height: 15%;">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :filters="filterTypes"
          :advance-filters="advanceFilters"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-barcode="{filter}">
            Barcode: {{ filter.value}}
          </template>

          <template v-slot:custom-gainType="{filter}">
            <v-select
              v-model="filter.value"
              :items="[
                {
                  name: 'target',
                  text: 'A obiettivo',
                },
                {
                  name: 'simple',
                  text: 'Semplice',
                },
                {
                  name: 'price',
                  text: 'Costo',
                },
              ]"
              label="Come lo guadagna"
              filled
              dense
              item-value="name"
              item-text="text"
              hide-details="auto"
            ></v-select>
          </template>

          <template v-slot:custom-itemGained="{filter}">
            <v-select
              v-model="filter.value"
              :items="[
                {
                  name: 'discount',
                  text: 'Sconto',
                },
                {
                  name: 'amount',
                  text: 'Importo',
                },
                {
                  name: 'services',
                  text: 'Servizi gratuiti',
                },
              ]"
              label="Cosa guadagna"
              filled
              dense
              item-value="name"
              item-text="text"
              hide-details="auto"
            ></v-select>
          </template>

          <template v-slot:custom-useTerms="{filter}">
            <v-select
              v-model="filter.value"
              :items="[
                {
                  name: 'oneShot',
                  text: 'One Shot',
                },
                {
                  name: 'oneShotRange',
                  text: 'One Shot Range',
                },
                {
                  name: 'range',
                  text: 'Range',
                },
                {
                  name: 'untillEnd',
                  text: 'Esaurimento',
                },
              ]"
              label="Come lo utilizza"
              filled
              dense
              item-value="name"
              item-text="text"
              hide-details="auto"
            ></v-select>
          </template>

          <template v-slot:custom-status="{filter}">
            <v-select
              v-model="filter.value"
              :items="[
                {text: 'Attiva', value: 'active'},
                {text: 'Non attiva', value: 'notActive'},
                {text: 'Attiva nelle date', value: 'activeInDates'},
              ]"
              label="Come lo utilizza"
              filled
              dense
              item-value="value"
              item-text="text"
              hide-details="auto"
            ></v-select>
          </template>

          <template v-slot:custom-barcode="{filter}">
            <v-text-field
              v-model="filter.value"
              label="Barcode"
              filled
              dense
              hide-details="auto"
            ></v-text-field>
          </template>
        </AdvancedFilter> 
      </div>
      <div style="height: 83%; overflow-y: hidden" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento promos ..."
          :headers="headers"
          :loading="loading"
          :items="filteredPromos"
          :translator="translator"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          @edit="handleEdit"
          @delete="handleDelete"
          :show-select="false"
          @dblclick:row="handleEditDoubleClick"
        >
          <template v-slot:custom-status="{item}">
            <v-checkbox 
              readonly
              :ripple="false"
              :input-value="item.status == 'active'"
              hide-details="auto"
              style="margin: auto"
            ></v-checkbox>
          </template>
        </TypeDataTable>
      </div>
      <StandardDialog
        v-model="showBarcode"
        :title="titleDelete"
        :dialog-height="null"
        dialog-max-width="500px"
        persistent
      >
        <ManualBarcodeInput
          v-model="barcodePermission"
          @confirm="deletePermission"
        >
        </ManualBarcodeInput>
      </StandardDialog>
    </template>
  </FullScreenDialog >
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import promosService from '@/services/promos/promos.service.js'
import dateUtils from '@/mixins/common/dateUtils.js'
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import StandardDialog from '@/components/common/StandardDialog.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import operatorService from '@/services/operators/operators.service.js';

export default {
  name: "PromosList",
  components: {
    FullScreenDialog,
    TypeDataTable,
    StandardMenu,
    ExportExcelTable,
    StandardDialog,
    ManualBarcodeInput,
    AdvancedFilter
  },
  mixins: [dateUtils],
  data: function() {
    let props = this.$router.resolve({name: 'Marketing'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      promos: [],
      headers: [],
      page: 1,
      rowPerPage: 20,
      totalPages: 0,
      resizeListener: undefined,
      worksheetName: "",
      fileName:"",
      fieldsToExport: {},
      barcodePermission: undefined,
      showBarcode: false,
      selectedDeletePromo: undefined,
      filterTypes: [
        { type: 'custom', operator: 'equal', field: 'gainType', name: 'Come lo Guadagna', label: 'Come lo Guadagna', color: "", value: undefined, icon: 'mdi-account-question' },
        { type: 'custom', operator: 'equal', field: 'itemGained', name: 'Cosa Guadagna', label:'Cosa Guadagna', color: "", value: undefined, icon: 'mdi-cash' },
        { type: 'custom', operator: 'equal', field: 'useTerms', name: 'Come lo Utilizza', label:'Come lo Utilizza', color: "", value: undefined, icon: 'mdi-account-cash' },
        { type: 'custom', operator: 'equal', field: 'status', name: 'Stato', label:'Stato', color: "", value: undefined, icon: 'mdi-clipboard-check-multiple' },
        { type: 'custom', operator: 'custom', field: 'barcode', name: 'Barcode', label: 'Barcode', color: "", value: undefined, icon: 'mdi-barcode'}
      ],
      advanceFilters: [
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'name', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [
      ],
      isLicenseBlocked: undefined
    }
  },
  props: {
    filters: {
      type: Array,
      default: function() {
        return [
        ]
      }
    }
  },
  mounted: function() {
    this.fetchHeaders()

    this.filtersValue = this.filters
    this.fetchPromos()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    }
    this.resizeListener = this.resizeListener.bind(this)

    this.fieldsToExport = promosService._fieldsToExport();
    this.worksheetName = "Export Promozioni ";
    this.fileName =
      "Promozioni_" + this._buildDateToExport(new Date()) +
      ".xls";

    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchPromos() {
      this.loadingItems = true      

      promosService.list(this.page || 1, this.rowPerPage, this.filtersValue).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page) {
          this.page = this.totalPages
        }

        this.promos = results.rows
        this.loadingItems = false
      })
    },
    fetchHeaders() {
      this.loadingHeaders = true
      promosService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    handleEdit(promo) {
      this.$router.push({
        name: 'PromosEditForm',
        params: {
          id: promo.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'PromosList'}).href,
          pathName: 'PromosList'
        }
      })
    },
    handleEditDoubleClick(row, {item: promo}) {
      this.$router.push({
        name: 'PromosEditForm',
        params: {
          id: promo.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'PromosList'}).href,
          pathName: 'PromosList'
        }
      })
    },
    deletePermission() {
      operatorService.canPerformOperation(this.barcodePermission, "Marketing", "delete").then((result) => {
        if (result) {
          promosService.archive(this.selectedDeletePromo).then(() => {
            this.fetchPromos()
          })
        } else {
          alert("Non hai i permessi per eseguire questa azione!")   
        }
      }).catch((error) => {
        console.log(error)
        alert("Badge non riconosciuto")
      })
      this.showBarcode = false
    },
    handleDelete(promo) {
      this.selectedDeletePromo = promo
      this.barcodePermission = undefined
      this.showBarcode = true
    },
    goToNew() {
      this.$router.push({
        name: 'PromosNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'PromosList'}).href
        }
      })
    },
    translator(key, value) {
      const gainTypeMapper = {
        target: 'A obiettivo',
        simple: 'Semplice',
        price: 'Costo',
      }

      const itemGainedMapper = {
        discount: 'Sconto',
        amount: 'Valore',
        services: 'Servizi',
        servicesDiscounted: 'Servizi scontati'
      }

      const useTermsMapper = {
        oneShot: 'One Shot',
        oneShotRange: 'One Shot Range',
        range: 'Range',
        untillEnd: 'Fino Esaurimento'
      }

      const rangeModeMapper = {
        dates: 'Date specificate',
        duration: 'Durata',
      }

      if(key == 'gainType') {
        return gainTypeMapper[value]
      } else if(key == 'itemGained') {
        return itemGainedMapper[value]
      } else if( key == 'useTerms') {
        return useTermsMapper[value]
      } else if( key == 'rangeMode') {
        return rangeModeMapper[value]
      }
    },
    async fetchExcel(){
      //let filters = [...this.filtersValue];
      let filters = undefined;

      let promos = (await promosService.list(undefined, 15000, filters)).rows

      return promos
    },
    applyFilters(filters) {
      this.fetchPromos()
    },
  },
  computed: {
    filteredPromos() {
      return this.promos
    },
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    },
    titleDelete() {
      if (this.selectedDeletePromo)
        return "Elimina " + this.selectedDeletePromo.name
      else 
        return "Elimina Promo"
    },
  },
  watch: {
    page() {
      this.fetchPromos()
    },
    rowPerPage() {
      this.fetchPromos()
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
}
</script>